// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #488aff;
  --ion-color-primary-rgb: 72,138,255;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3f79e0;
  --ion-color-primary-tint: #5a96ff;

  /** secondary **/
  --ion-color-secondary: #32db64;
  --ion-color-secondary-rgb: 50,219,100;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #2cc158;
  --ion-color-secondary-tint: #47df74;

  /** tertiary **/
  --ion-color-tertiary: #f4a942;
  --ion-color-tertiary-rgb: 244,169,66;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #d7953a;
  --ion-color-tertiary-tint: #f5b255;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f53d3d;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d83636;
  --ion-color-danger-tint: #f65050;

  /** light **/
  --ion-color-light: #f4f4f4;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d7d7;
  --ion-color-light-tint: #f5f5f5;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  
  /** dark **/
  --ion-color-dark: #222;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e1e1e;
  --ion-color-dark-tint: #383838;


  /** MTG Atlas CSS variables **/
  // Selection colors
  --default-unselected-bg: #111111; // round icon background for selections

  --maintype-unselected: #CAC5C0;
  --white-unselected: #F0F2C0;
  --blue-unselected: #B5CDE3; 
  --black-unselected: #ACA29A; 
  --red-unselected: #DB8664;
  --green-unselected: #93B483;
  --colorless-unselected: #BEB9B2;
  --cmc-unselected: #CAC5C0;

  --maintype-selected: #4F6D7A;
  --maintype-selected-bg: #BEB9B2;
  --white-selected: #A9AA79;
  --white-selected-bg: #F0F2C0;
  --blue-selected: #3479BA;
  --blue-selected-bg: #B5CDE3; 
  --black-selected: #140B05; 
  --black-selected-bg: #ACA29A; 
  --red-selected: #8C2700;
  --red-selected-bg: #DB8664;
  --green-selected: #1F3316;
  --green-selected-bg: #93B483;
  --colorless-selected: #705228;
  --colorless-selected-bg: #BEB9B2;
  --cmc-selected: #111111;
  --cmc-selected-bg: #B7B2AB;
  --favourite: #EECF6D;

  /* Color palettes */
  // https://coolors.co/011627-b8336a-488aff-5c9ead-f7f7f2


  // Dark blue color scheme
  --background-color: #011627;
  --background-color-dark: #111111;
  --background-color-imagescroll: #F7F7F2;
  --background-color-selectionarea: #060006;
  --border-color: #F7F7F2; 
  --font-color: #488AFF;
  --category-font-color: #F7F7F2; // alternative font color
  --dropdown-font-color: #E43939; // not yet used
  --yesnoselection-font-color: #F7F7F2;
  --yesnoselection-colors: #008000, #ff6c00, #ff0000; /* colors: Y, ?, N */
  --yesnoselection-color1: #008000; /* colors: Y, ?, N */
  --yesnoselection-color2: #ff6c00; /* colors: Y, ?, N */
  --yesnoselection-color3: #ff0000; /* colors: Y, ?, N */

  // Brown color scheme
  --background-color: #161116; 
  --background-color-dark: #111111;
  --background-color-imagescroll: #F7F7F2;
  --background-color-selectionarea: #060006;
  --border-color: #F7F7F2; 
  --font-color: #526C79; 
  --category-font-color: #999; //#CAC5C0; // alternative font color
  --dropdown-font-color: #526C79; 
  --yesnoselection-font-color: #F7F7F2;
  --yesnoselection-colors: #1F3316, #ff6c00, #8C2700; /* colors: Y, ?, N */
  --yesnoselection-color1: #1F3316; /* colors: Y, ?, N */
  --yesnoselection-color2: #ff6c00; /* colors: Y, ?, N */
  --yesnoselection-color3: #8C2700; /* colors: Y, ?, N */

  // Dependent color parameters
  --background-color-logo: var(--background-color);
  --background-color-searcharea: var(--background-color);
  --background-color-dropdownarea: var(--background-color);
  --background-color-yesnoheader: var(--background-color);
  --background-color-yesnoselections: var(--background-color);
  --yesnolabel-font-color: var(--font-color);
  --tooltip-font-color: var(--background-color); 
  --tooltip-color: var(--font-color); 

  // Overriding ionic defaults below
  // IOS
  --ion-toolbar-ios-active-color: var(--font-color);
  --ion-segment-button-ios-background-color: var(--background-color); 
  --ion-segment-button-ios-background-color-activated: var(--font-color); 
  --ion-segment-button-ios-text-color: var(--category-font-color);
  --ion-segment-button-ios-border-width: 0px;

  --popover-ios-min-height: 90px; //variable might need updating with ionic@6

  // Material Design
  --ion-toolbar-md-active-color: var(--font-color); 
  --ion-segment-button-md-background-color: var(--background-color); 
  --ion-segment-button-md-background-color-activated: var(--font-color);
  --ion-segment-button-md-text-color-activated: var(--font-color);

  --popover-md-min-height: 90px;

  // Windows
  --ion-segment-button-wp-background-color: var(--background-color); 
  --ion-segment-button-wp-text-color-activated: var(--category-font-color);

  --popover-wp-min-height: 90px;

  // For ion-items
  --ion-item-background-color: rgba(0,0,0,0);
  --ion-item-background-color-active: rgba(0,0,0,0);

  // For ion-modals
  --ion-modal-max-height: 400px;

  // For general purposes
  --ion-color-primary: var(--font-color);
  --ion-background-color: var(--background-color);

  // For buttons
  /** dark **/
  --ion-color-dark: var(--background-color);
  --ion-color-dark-rgb: 22,17,22;
  --ion-color-dark-contrast: var(--font-color);
  --ion-color-dark-contrast-rgb: 82,108,121;
  --ion-color-dark-shade: var(--background-color-dark);
  --ion-color-dark-tint: #383838;

}