// http://ionicframework.com/docs/theming/

// Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.

// Importing support for set icons and rarities
@import './theme/keyrune/keyrune.scss';

// Importing support for mana icons and main types
@import './theme/mana/mana.scss';

// Importing default ionic global themes
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Font face for card text
@font-face {
    font-family: "CardFont";
    src: 
    url("./theme/fonts/mplantin.eot");
    src: 
    url("./theme/fonts/mplantin.woff") format("woff"),
    url("./theme/fonts/mplantin.ttf") format("truetype"),
    url("./theme/fonts/mplantin.svg#filename") format("svg");
}

/* CSS for global text size */
html {
    width: 100%;
    height: 100%;
    font-size: 87.5%;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%
}

/* CSS for loading spinner */
.spinner {
    position: fixed;
    left: 0;
    right: 0;
    top: 46%;
    margin: 100px auto 0;
    width: 420px;
    text-align: center;
    z-index: 1001; /* anything higher than z-index: 1000 of .loader-section */
    h1 {
        color: var(--background-color-dark);
    }
  }

// Use a different spinner size with smaller screens
@media screen and (max-width: 375px) {
    .spinner {
        position: fixed;
        left: 0;
        right: 0;
        top: 20%;
        margin: 100px auto 0;
        width: 375px;
        text-align: center;
        z-index: 1001; /* anything higher than z-index: 1000 of .loader-section */
    }
}

.spinner > div {
    width: 80px;
    height: 80px;
    //background-color: red;
    z-index: 1001; /* anything higher than z-index: 1000 of .loader-section */
  
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
// Use a different spinner size with smaller screens
@media screen and (max-width: 375px) {
  .spinner > div {
    width: 60px;
    height: 60px;
    //background-color: red;
    z-index: 1001; /* anything higher than z-index: 1000 of .loader-section */
  
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
}
  
.spinner .bounce1 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
    z-index: 1001;
    .ms-cost.ms-w{
        color: var(--white-selected);
        background: var(--white-selected-bg);
    }
}  
.spinner .bounce2 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
    .ms-cost.ms-u{
        color: var(--blue-selected);
        background: var(--blue-selected-bg);
    }
}
.spinner .bounce3 {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
    .ms-cost.ms-r{
        color: var(--red-selected);
        background: var(--red-selected-bg);
    }
}
.spinner .bounce4 {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
    .ms-cost.ms-b{
        color: var(--black-selected);
        background: var(--black-selected-bg);
    }
}
.spinner .bounce5 {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
    .ms-cost.ms-g{
        color: var(--green-selected);
        background: var(--green-selected-bg);
    }
}
  
@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}
  
@keyframes sk-bouncedelay {
    0%, 80%, 100% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% { 
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

#loader-wrapper,
#loader-wrapper-nojs {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

#loader-wrapper .loader-section,
#loader-wrapper-nojs .loader-section {
    position: fixed;
    top: 0;
    width: 50%;
    height: 100%;
    background: var(--background-color);
    z-index: 1000;
}
 
#loader-wrapper .loader-section.section-left,
#loader-wrapper-nojs .loader-section.section-left {
    left: 0;
    background-color: #999;
    @media screen and (max-width: 640px){
        background-image: url(./assets/gui/metal_doors_mobile.jpg);
    }
    @media screen and (min-width: 641px){
        background-image: url(./assets/gui/metal_doors.jpg);
    }
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
}
 
#loader-wrapper .loader-section.section-right,
#loader-wrapper-nojs .loader-section.section-right {
    right: 0;
    background-color: #999;
    @media screen and (max-width: 640px){
        background-image: url(./assets/gui/metal_doors_mobile.jpg);
    }
    @media screen and (min-width: 641px){
        background-image: url(./assets/gui/metal_doors.jpg);
    }
    background-repeat: no-repeat;
    background-position: center left;
    background-size: cover;
}

/* Loaded */
.loaded #loader-wrapper .loader-section.section-left,
.loaded #loader-wrapper-nojs .loader-section.section-left {
    -webkit-transform: translateX(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: translateX(-100%);  /* IE 9 */
            transform: translateX(-100%);  /* Firefox 16+, IE 10+, Opera */
    //-webkit-transition: all 0.5s 0.5s ease-out; 
    //        transition: all 0.5s 0.5s ease-out;
    -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000); 
            transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);

}
 
.loaded #loader-wrapper .loader-section.section-right,
.loaded #loader-wrapper-nojs .loader-section.section-right {
    -webkit-transform: translateX(100%);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: translateX(100%);  /* IE 9 */
            transform: translateX(100%);  /* Firefox 16+, IE 10+, Opera */
    //-webkit-transition: all 0.5s 0.5s ease-out; 
    //        transition: all 0.5s 0.5s ease-out;
    -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000); 
            transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);

}

.loaded .spinner {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out; 
            transition: all 0.3s ease-out;
}

.loaded #loader-wrapper,
.loaded #loader-wrapper-nojs {
    //visibility: hidden;
    -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
            transform: translateY(-100%);

    -webkit-transition: all 0.3s 1s ease-out; 
            transition: all 0.3s 1s ease-out;
}

// Modals
// --------------------------------------------------

ion-modal::part(content) {
//ion-modal { 
    //.modal-wrapper {
        @media only screen and (min-height: 768px) and (min-width: 768px) {
            --max-width: 620px;
            --max-height: 400px;
            --border-radius: 5px;
        }
}

// Styling for introduction tooltip box
.introjs-tooltipReferenceLayer {
    top: 5% !important; // overwriting original tooltip position
}
.introjs-tooltip {
    background-color: rgba(6, 0, 6, .5);
    color: #F7F7F2;
    margin-top: 0px !important; // Fixing bug with tooltip position moving up when going back
    // Below should be depending on screen resolution
    @media screen and (min-width: 800px) and (max-width: 1199px) {
        min-width: 700px !important; // overwriting original tooltip width
    }
    @media screen and (min-width: 1200px) and (max-width: 1599px) {
        min-width: 1000px !important; // overwriting original tooltip width
    }
    @media screen and (min-width: 1600px) {
        min-width: 1200px !important; // overwriting original tooltip width
    }
}

.introjs-bullets ul li a.active {
    background: #526C79;
}

.introjs-button {
    box-sizing: border-box;
    background-image: initial;
    border: 0;
    margin: 0;
    text-shadow: none;
    width: 40px;
    height: 30px;
    // display: table-cell;
    // vertical-align: middle;
    text-align: center;
}
  
  .introjs-button:hover, 
  .introjs-button:focus,
  .introjs-button:active {
    // background:#fff !important;
    background-image: initial;
    color:#F7F7F2;
    // border-color: initial;
    border-color: rgba(0,0,0,.7);
  }

.introjs-tooltipbuttons {
    text-align: initial;
}

.introjs-skipbutton {
    display: inline-block;
    background-color: #f53d3d;
    color: #F7F7F2;
    font-size: 1.2em;
    @media screen and (min-width: 800px) and (max-width: 1199px) {
        left: 350px - 20px; 
    }
    @media screen and (min-width: 1200px) and (max-width: 1599px) {
        left: 500px - 20px; 
    }
    @media screen and (min-width: 1600px) {
        left: 600px - 20px; 
    }
}

.introjs-donebutton {
    display: inline-block;
    background-color: #10dc60;
    color: #F7F7F2;
    font-size: 1.2em;
    @media screen and (min-width: 800px) and (max-width: 1199px) {
        left: 350px - 20px; 
    }
    @media screen and (min-width: 1200px) and (max-width: 1599px) {
        left: 500px - 20px; 
    }
    @media screen and (min-width: 1600px) {
        left: 600px - 20px; 
    }
}

.introjs-prevbutton,
.introjs-nextbutton {
    display: inline-block;
    padding: .5em .6em;
    margin: .5em 0em;
    background: rgba(0,0,0,.2);
    border-radius: 5px;
    box-shadow: 0.05em 0.1em 0.2em rgba(0,0,0,.7) inset;
    color: #F7F7F2;
    font-size: 1.4em;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    opacity: 0.7;
    border-style: none;
}

.introjs-prevbutton.introjs-fullbutton {
    border: 0;
}

.introjs-prevbutton {
    @media screen and (min-width: 800px) and (max-width: 1199px) {
        left: 0px; 
    }
    @media screen and (min-width: 1200px) and (max-width: 1599px) {
        left: 0px; 
    }
    @media screen and (min-width: 1600px) {
        left: 0px;  
    }
}

.introjs-nextbutton {
    @media screen and (min-width: 800px) and (max-width: 1199px) {
        left: 700px - 40px - 40px - 40px - 40px; 
    }
    @media screen and (min-width: 1200px) and (max-width: 1599px) {
        left: 1000px - 40px - 40px - 40px - 40px; 
    }
    @media screen and (min-width: 1600px) {
        left: 1200px - 40px - 40px - 40px - 40px;  
    }
}